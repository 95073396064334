import type { FormValidationTranslationsObject } from "../../translation-keys/form-validation-keys.js";

const FormValidation: FormValidationTranslationsObject = {
  eclipse_FirstName_Error: "名を入力してください。",
  eclipse_LastName_Error: "姓を入力してください。",
  eclipse_Email_Error: "メールアドレスを入力してください。",
  eclipse_Company_Error: "会社名を入力してください。",
  eclipse_Country_Error: "国を入力してください。",
  eclipse_Phone_Error: "電話番号を入力してください。",
  eclipse_Tier__c_Error: "会社規模を選択してください。",
  eclipse_Industry_Error: "業界を選択してください。",
  eclipse_Department__c_Error: "部署を選択してください。",
  eclipse_Email_Opt_In__c_Error: "この項目は必須です。",
  eclipse_contactRequested_Error: "",         //need more info on this
  eclipse_Webform_Comments_recent_value__c_Error: "", //need more info on this
  eclipse_Latest_Solution_Interest__c_Error: "このフィールドへの入力は必須です",  
  eclipse_Entity_Error:"法人名を入力します",
  eclipse_NonBusinessEmail_Error: "メールアドレスを入力してください。",
  eclipse_Partner_Opt_in_Processing__c_Error: "",
};

export default FormValidation;
